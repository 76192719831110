form {
    display: flex;
    margin-right: -5px;
    margin-left: -5px;
    flex-wrap: wrap;
    .check {
        margin-top: 15px;
        position: relative;
        input {
            position: absolute;
            left: 7px;
            top: 7px;
            width: 1px;
            height: 1px;
            opacity: 0.1;
            pointer-events: none;

            + label {
                cursor: pointer;
                text-align: left;
                display: grid;
                grid-template-columns: 25px 1fr;
                gap: 5px;
                text-align: justify;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 140%;
                letter-spacing: -0.025em;
                align-items: flex-start;
                color: var(--ion-text-color);
                span.checkbox {
                    width: 22px;
                    height: 22px;
                    border: 1px solid #d1d1d1;
                    background-color: white;
                    border-radius: 6px;
                    position: relative;

                    &::before {
                        content: "";
                        width: 14px;
                        height: 14px;
                        background-color: var(--ion-color-primary);
                        position: absolute;
                        top: 3px;
                        left: 3px;
                        border-radius: 3px;
                        transition: 0.3s;
                        opacity: 0;
                    }
                }

                span {
                    a {
                        color: var(--ion-text-color);
                        font-weight: 700;
                        transition: 0.3s;
                        &:hover {
                            color: var(--ion-color-primary-tint);
                        }
                    }
                }
            }

            &:checked {
                + label {
                    span {
                        &::before {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    .submit {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 15px 5px;
        @media (max-width: 991px) {
            flex-direction: column;
            gap: 15px;
            button {
                width: 100%;
            }
        }
        .check {
            margin: 0;
        }
        button {
            font-weight: 600;
            padding: 14px 24px;
            background-color: var(--ion-color-primary);
            color: var(--ion-color-primary-contrast);
            border-radius: 30px;
            border: 0;
            cursor: pointer;
            transition: 0.2s;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: -0.025em;
            &:hover {
                filter: brightness(0.7);
                color: var(--ion-color-primary-contrast);
            }
        }
    }
}
.form-group {
    position: relative;
    margin-top: 20px;
    input,
    textarea {
        background: transparent;
        border: 1px solid var(--ion-color-primary);
        box-sizing: border-box;
        border-radius: 0;
        height: 44px;
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 21px;
        color: var(--ion-text-color);
        letter-spacing: -0.025em;
        padding-left: 15px;
        border-radius: 50px;
        background-color: white;
        + label {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            letter-spacing: -0.025em;
            color: var(--ion-text-color);
            opacity: 1;
            position: absolute;
            left: 15px;
            top: 15px;
            transition: 0.3s;
            pointer-events: none;
            padding: inherit;
            font-size: 13px;
            line-height: 100%;
        }

        &:focus,
        &:valid,
        &:active {
            + label {
                top: -15px;
                left: 10px;
                opacity: 1;
                text-transform: lowercase;
            }
        }
    }

    textarea {
        padding: 15px;
        border-radius: 25px;
        height: 100px;
        resize: none;
    }

    select {
        width: 100%;
        height: 48px;
        border: 1px solid var(--ion-color-primary);
        background-color: transparent;
        border-radius: 50px;
        color: var(--ion-text-color);
        padding-left: 10px;
        -webkit-appearance: none;
        appearance: none;

        + label {
            font-style: normal;
            font-weight: normal;
            letter-spacing: -0.025em;
            position: absolute;
            transition: 0.3s;
            pointer-events: none;
            top: -15px;
            left: 10px;
            opacity: 1;
            text-transform: lowercase;
        }

        option {
            color: black;
        }
    }
}
