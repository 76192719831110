#newsletter {
    background-color: #f4f4f6;
    padding: 90px 0;
    .container {
        max-width: 900px;
        h3 {
            font-style: normal;
            font-weight: 800;
            font-size: 36px;
            line-height: 100%;
            text-align: center;
            letter-spacing: -0.025em;
            color: var(--ion-color-primary);
            margin-bottom: 15px;
            @media (max-width: 991px) {
                font-size: 18px;
            }
        }
    }
}
