#footer {
    .footer {
        background-color: var(--ion-color-secondary);
        padding: 75px 0 25px;
        .container {
            display: grid;
            grid-template-columns: 2fr 1fr 1fr 1fr;
            @media (max-width: 991px) {
                grid-template-columns: 1fr;
                gap: 35px;
            }
            > div {
                > a {
                    display: inline-block;
                    img {
                        width: 140px;
                        height: 45px;
                    }
                }

                p {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 150%;
                    letter-spacing: -0.025em;
                    color: var(--ion-color-secondary-contrast);
                    margin: 10px 0 15px;
                }

                .socials {
                    display: flex;
                    gap: 15px;
                    a {
                        display: block;
                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }

                h4 {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 150%;
                    letter-spacing: -0.025em;
                    color: var(--ion-color-secondary-contrast);
                }

                .menu {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    margin-top: 30px;
                    a {
                        font-style: normal;
                        font-weight: normal;
                        font-size: 14px;
                        line-height: 150%;
                        letter-spacing: -0.025em;
                        color: var(--ion-color-secondary-contrast);
                        transition: 0.2s;
                        &:hover {
                            color: var(--ion-color-primary);
                        }
                    }
                }
            }
        }
    }

    .credits {
        background-color: var(--ion-color-secondary);
        .container {
            padding-top: 25px;
            padding-bottom: 25px;
            border-top: 1px solid var(--ion-color-secondary-contrast);
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media(max-width: 991px) {
                flex-direction: column;
            }
            p {
                color: var(--ion-color-secondary-contrast);
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 150%;
                letter-spacing: -0.025em;
                @media(max-width: 991px) {
                    text-align: center;
                }

                a {
                    color: var(--ion-color-secondary-contrast);
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 150%;
                    letter-spacing: -0.025em;
                }
            }
        }
    }
}
