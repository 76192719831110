#header {
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    .container {
        .topbar {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 15px;
            margin-bottom: 25px;
            border-bottom: 1px solid var(--ion-text-color);
            padding-bottom: 10px;
            > div {
                display: flex;
                gap: 10px;
                align-items: center;
                button {
                    background-color: transparent;
                    border: 0;
                    padding: 0;
                    cursor: pointer;
                    filter: saturate(0);
                    transition: 0.2s;
                    @media(max-width: 991px) {
                        display: none;
                    }
                    &.active,
                    &:hover {
                        filter: saturate(1);
                    }
                    img {
                        width: 18px;
                        height: 18px;
                    }
                }
                p {
                    margin: 0;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 100%;
                    letter-spacing: -0.025em;
                    color: var(--ion-text-color);
                    @media (max-width: 991px) {
                        display: none;
                    }
                }

                a {
                    display: block;
                    &:hover {
                        filter: saturate(20) hue-rotate(320deg);
                    }
                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 25px;
            > a {
                img {
                    width: 220px;
                    height: 45px;
                }
            }

            button.toggleMenu {
                width: 40px;
                height: 40px;
                display: none;
                position: relative;
                z-index: 99999;
                align-items: center;
                justify-content: center;
                border-radius: 0;
                border: 0;
                background-color: var(--ion-color-primary);
                transition: 0.3s;
                img {
                    width: 24px;
                    height: 24px;
                }

                &.active {
                    border-radius: 50%;
                    img {
                        width: 16px;
                        height: 16px;
                    }
                }

                @media (max-width: 991px) {
                    display: flex;
                }
            }

            .menu {
                height: 100%;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 25px;

                a {
                    display: block;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 100%;
                    letter-spacing: -0.025em;
                    color: var(--ion-color-primary);
                    transition: .2s;
                    &:last-child {
                        font-weight: 600;
                        padding: 14px 24px;
                        background-color: var(--ion-color-primary);
                        color: var(--ion-color-primary-contrast);
                        border-radius: 30px;
                        &:hover {
                            filter: brightness(.7);
                            color: var(--ion-color-primary-contrast);
                        }
                    }

                    &:hover {
                        color: var(--ion-color-secondary);
                    }
                }

                @media (max-width: 991px) {
                    position: fixed;
                    z-index: 9999;
                    top: 0;
                    right: -400px;
                    width: 300px;
                    background-color: #cccccc;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    gap: 10vh;
                    transition: 0.3s;
                    box-shadow: 0 0 50px rgba(0, 0, 0, 0.3);

                    &.active {
                        right: 0;
                    }
                }
            }
        }
    }
}
