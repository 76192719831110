.form-group {
    padding: 0 5px;
    @for $i from 1 through 12 {
        &.s-#{$i} {
            width: calc(100% / 12 * #{$i});
            flex: 0 0 calc(100% / 12 * #{$i});
        }
    }

    .arrow {
        width: 16px;
        height: 16px;
        position: absolute;
        top: calc(24px - 7px);
        right: 20px;
        pointer-events: none;
    }

    input,
    textarea,
    select {
        + label {
            padding: inherit;
            font-size: 13px;
            line-height: 100%;
        }

        & {
            & + label {
                color: var(--ion-text-color);
            }
        }
    }

    button.file-uploader {
        width: 100% !important;
        background-color: var(--ion-color-primary) !important;
        color: white;
        height: 44px;
        border-radius: 50px;
        border: 0;
        cursor: pointer;
        + input[type="file"] {
            display: none;

            + label {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                letter-spacing: -0.025em;
                color: var(--ion-text-color);
                opacity: 1;
                position: absolute;
                top: -15px;
                left: 0;
                transition: 0.3s;
                pointer-events: none;
                padding: inherit;
                font-size: 13px;
                line-height: 100%;
            }
        }
    }

    @media (max-width: 991px) {
        width: 100%;
        flex: 0 0 100%;
    }

    .cidade {
        display: flex;
        gap: 10px;
        > div {
            position: relative;
            width: 100%;
            &.uf {
                width: 30%;
            }
        }
        + label {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            letter-spacing: -0.025em;
            color: var(--ion-text-color);
            opacity: 1;
            position: absolute;
            top: -15px;
            left: 5px;
            text-transform: lowercase;
            transition: 0.3s;
            pointer-events: none;
            padding: inherit;
            font-size: 13px;
            line-height: 100%;
        }
    }
}
